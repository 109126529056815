import $ from 'jquery';
import Inputmask from 'inputmask';

let init = true;

const pis = (contexto, resetInit = false) => {

  if (resetInit === true) {
    init = true;
  }

  const prefixo = (contexto !== '' ? `${contexto}-` : '');
  /**
   * Código de Situação Tributária do PIS
   * @type {jQuery|HTMLElement|*}
   */
  const cst = $(`#${prefixo}pis-imposto-pis-dado-id`);

  /**
   * Tipo de Cálculo Pis
   * @type {jQuery|HTMLElement|*}
   */
  const tipoCalculo = $(`#${prefixo}pis-imposto-tipo-calculo`);

  /**
   * Alíquota do PIS
   * @type {jQuery|HTMLElement|*}
   */
  const aliq = $(`#${prefixo}pis-imposto-valor`);

  /**
   * Bloco Pis St
   * @type {jQuery|HTMLElement|*}
   */
  const st = $(`#${prefixo}pis-st`);

  /**
   * Tipo Cálculo PIS ST
   * @type {jQuery|HTMLElement|*}
   */
  const tipoCalculoSt = $(`#${prefixo}pis-imposto-tipo-calculo-st`);

  /**
   * Alíquota do PIS ST (em percentual)
   * @type {jQuery|HTMLElement|*}
   */
  const aliqSt = $(`#${prefixo}pis-imposto-valor-st`);

  /**
   * Habilitar input
   * @param  {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const habilitar = (field) => {
    field
      .prop('required', true)
      .closest('.form-group')
      .addClass('required')
      .show();
    return field;
  };

  /**
   * Desabilitar input
   * @param  {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const desabilitar = (field) => {
    if (!init) {
      if (field === tipoCalculoSt) {
        field.val(0).trigger('change.select2');
      } else if (field === tipoCalculo) {
        field.val(1).trigger('change.select2');
      } else {
        field.val(null);
      }
    }
    field
      .prop('required', false)
      .closest('.form-group')
      .removeClass('required')
      .hide();
    return field;
  };

  /**
   * Altera input para porcentagem
   * @param {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const porcentagem = (field) => {
    const container = field.closest('.input-group');

    container.find('span').remove();
    container.append('<span class="input-group-append"><span class="input-group-text">%</span></span>');
    Inputmask().remove(field);
    field.attr('data-inputmask-alias', 'percentage');
    Inputmask().mask(field);

    return field;
  };

  /**
   * Altera input para monetário
   * @param {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const monetario = (field) => {
    const container = field.closest('.input-group');

    container.find('span').remove();
    container.prepend('<span class="input-group-prepend"><span class="input-group-text">R$</span></span>');
    Inputmask().remove(field);
    field.attr('data-inputmask-alias', 'currency');
    Inputmask().mask(field);

    return field;
  };

  cst.on('change.select2', () => {
    /**
     * Código Situação Tributária
     * @type {*|jQuery}
     */
    const codigo = cst.find('option:checked').data('codigo');

    desabilitar(tipoCalculo);
    desabilitar(aliq);
    st.hide();
    desabilitar(tipoCalculoSt);
    desabilitar(aliqSt);

    if (typeof codigo !== 'undefined') {
      switch (codigo.toString()) {
        case '01': /** Operação Tributável com Alíquota Básica */
        case '02':
          /** Operação Tributável com Alíquota Diferenciada */
          tipoCalculo.val(1).trigger('change.select2');
          habilitar(aliq);
          break;
        case '03':
          /** Operação Tributável com Alíquota por Unidade de Medida de Produto */
          tipoCalculo.val(2).trigger('change.select2');
          habilitar(aliq);
          break;
        case '04': /** Operação Tributável Monofásica – Revenda a Alíquota Zero */
          break;
        case '05':
          /** Operação Tributável por Substituição Tributária */
          habilitar(tipoCalculoSt).trigger('change.select2');
          st.show();
          break;
        case '06': /** Operação Tributável a Alíquota Zero */
        case '07': /** Operação Isenta da Contribuição */
        case '08': /** Operação sem Incidência da Contribuição */
        case '09': /** Operação com Suspensão da Contribuição */
          break;
        case '49': /** Outras Operações de Saída */
        case '50': /** Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Tributada no Mercado Interno */
        case '51': /** Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno */
        case '52': /** Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação */
        case '53': /** Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno */
        case '54': /** Operação com Direito a Crédito – Vinculada a Receitas Tributadas no Mercado Interno e de Exportação */
        case '55': /** Operação com Direito a Crédito – Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação */
        case '56': /** Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação */
        case '60': /** Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno */
        case '61': /** Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno */
        case '62': /** Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação */
        case '63': /** Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas e Não Tributadas no Mercado Interno */
        case '64': /** Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação */
        case '65': /** Crédito Presumido – Operação de Aquisição Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação */
        case '66': /** Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas e Não Tributadas no Mercado Interno, e de Exportação */
        case '67': /** Crédito Presumido – Outras Operações */
        case '70': /** Operação de Aquisição sem Direito a Crédito */
        case '71': /** Operação de Aquisição com Isenção */
        case '72': /** Operação de Aquisição com Suspensão */
        case '73': /** Operação de Aquisição a Alíquota Zero */
        case '74': /** Operação de Aquisição sem Incidência da Contribuição */
        case '75': /** Operação de Aquisição por Substituição Tributária */
        case '98': /** Outras Operações de Entrada */
        case '99':
          /** Outras Operações */
          habilitar(tipoCalculo).trigger('change.select2');
          habilitar(aliq);
          break;
        default:
          break;
      }
    }
    init = false;
  });

  tipoCalculo.on('change.select2', () => {
    switch (tipoCalculo.val()) {
      case '1':
        /** Porcentagem */
        porcentagem(aliq);
        break;
      case '2':
        /** Em valor */
        monetario(aliq);
        break;
      default:
        break;
    }
  });

  tipoCalculoSt.on('change.select2', () => {
    switch (tipoCalculoSt.val()) {
      case '0':
        /** Não usar */
        desabilitar(aliqSt);
        break;
      case '1':
        /** Porcentagem */
        habilitar(porcentagem(aliqSt));
        break;
      case '2':
        /** Em Valor */
        habilitar(monetario(aliqSt));
        break;
      default:
        break;
    }
  });

  cst.trigger('change.select2');
};

window.initPis = pis;
