import $ from 'jquery';

let init = true;

const icms = (contexto, resetInit = false) => {

  if (resetInit === true) {
    init = true;
  }

  const prefixo = (contexto !== '' ? `${contexto}-` : '');
  /**
   * Situação Tributária Simples Nacional
   * @type {string[]}
   */
  const simplesNacional = ['101', '102', '103', '201', '202', '203', '300', '400', '500', '900'];

  /**
   * Situação Tributária Regime Normal
   * @type {string[]}
   */
  const regimeNormal = ['00', '10', '20', '30', '40', '41', '50', '51', '60', '70', '90'];

  /**
   * Situação Tributária Partilha e Repasse
   * @type {string[]}
   */
  const partilhaRepasse = ['Partilha 10', 'Partilha 90', 'Repasse 41', 'Repasse 60'];

  /**
   * Código de Situação da Operação
   * @type {jQuery|HTMLElement|*}
   */
  const csosn = $(`#${prefixo}icms-imposto-icms-dado-id`);

  /**
   * Tributação do ICMS - NFce
   * @type {jQuery|HTMLElement|*}
   */
  const cst = $(`#${prefixo}icms-imposto-icms-dado-nfce-id`);

  /**
   * Modalidade de determinação da BC do ICMS
   * @type {jQuery|HTMLElement|*}
   */
  const modBc = $(`#${prefixo}icms-imposto-modalidade-bc`);

  /**
   * Percentual da Redução de BC
   * @type {jQuery|HTMLElement|*}
   */
  const redBc = $(`#${prefixo}icms-imposto-red-bc`);

  /**
   * Alíquota do imposto
   * @type {jQuery|HTMLElement|*}
   */
  const aliqCalCred = $(`#${prefixo}icms-imposto-aliquota-calculo-credito`);

  /**
   * Alíquota aplicável de cálculo do crédito
   * @type {jQuery|HTMLElement|*}
   */
  const aliqIcms = $(`#${prefixo}icms-imposto-valor`);

  /**
   * Alíquota ICMS ST Retido
   * @type {jQuery|HTMLElement|*}
   */
  const aliqIcmsRet = $(`#${prefixo}icms-imposto-aliquota-st-retido`);

  /**
   * Percentual do ICMS relativo ao Fundo de Combate à Pobreza (FCP)
   * @type {jQuery|HTMLElement|*}
   */
  const aliqFcp = $(`#${prefixo}icms-imposto-aliquota-fcp-normal`);

  /**
   * Percentual do FCP retido anteriormente por Substituição Tributária
   * @type {jQuery|HTMLElement|*}
   */
  const aliqFcpRet = $(`#${prefixo}icms-imposto-aliquota-fcp-retido`);

  /**
   * Bloco Icms St
   * @type {jQuery|HTMLElement|*}
   */
  const st = $(`#${prefixo}icms-st`);

  /**
   * Modalidade de determinação da BC do ICMS ST
   * @type {jQuery|HTMLElement|*}
   */
  const modBcSt = $(`#${prefixo}icms-imposto-modalidade-bcst`);

  /**
   * Preço unit. Pauta ST
   * @type {jQuery|HTMLElement|*}
   */
  const precUniSt = $(`#${prefixo}icms-imposto-preco-init-pauta-st`);

  /**
   * Percentual da margem de valor Adicionado do ICMS ST
   * @type {jQuery|HTMLElement|*}
   */
  const margAdcSt = $(`#${prefixo}icms-imposto-margem-valor-adc`);

  /**
   * Percentual da Redução de BC do ICMS ST
   * @type {jQuery|HTMLElement|*}
   */
  const redBcSt = $(`#${prefixo}icms-imposto-red-bcst`);

  /**
   * Alíquota do imposto do ICMS ST
   * @type {jQuery|HTMLElement|*}
   */
  const aliqIcmsSt = $(`#${prefixo}icms-imposto-valor-st`);

  /**
   * Percentual do ICMS relativo ao Fundo de Combate Pobreza (FCP)
   * @type {jQuery|HTMLElement|*}
   */
  const aliqFcpSt = $(`#${prefixo}icms-imposto-aliquota-fcp-normal-st`);

  /**
   * Percentual do FCP retido por Substituição Tributária
   * @type {jQuery|HTMLElement|*}
   */
  const aliqFcpRetSt = $(`#${prefixo}icms-imposto-aliquota-fcp-st`);

  /**
   * Percentual da BC operação própria
   * @type {jQuery|HTMLElement|*}
   */
  const aliqBcOP = $(`#${prefixo}icms-imposto-aliq-bc-op`);

  /**
   * UF para qual é devido o ICMS ST
   * @type {jQuery|HTMLElement|*}
   */
  const ufSt = $(`#${prefixo}icms-imposto-uf-st`);

  /**
   * Habilitar input
   * @param field
   * @returns {*}
   */
  const habilitar = (field) => {
    field
      .prop('required', true)
      .closest('.form-group')
      .addClass('required')
      .show();
    return field;
  };

  /**
   * Desabilitar input
   * @param field
   * @returns {*}
   */
  const desabilitar = (field) => {
    if (!init) {
      if (field.attr('type') === 'text') {
        field.val(null);
      } else if (field === cst) {
        field.val(null).trigger('change.select2');
      } else {
        field.val(0);
      }
    }
    field
      .prop('required', false)
      .closest('.form-group')
      .removeClass('required')
      .hide();
    return field;
  };

  csosn.on('change.select2', () => {
    /**
     * Código Situação Tributária
     * @type {*|jQuery}
     */
    const codigo = csosn.find('option:checked').data('codigo');

    /**
     * Específico Nfe
     * @type {*|jQuery}
     */
    const nfce = csosn.find('option:checked').data('nfce');

    desabilitar(cst);
    desabilitar(modBc);
    desabilitar(redBc);
    desabilitar(aliqCalCred);
    desabilitar(aliqIcms);
    desabilitar(aliqIcmsRet);
    desabilitar(aliqFcp);
    desabilitar(aliqFcpRet);
    st.hide();
    desabilitar(modBcSt);
    desabilitar(precUniSt);
    desabilitar(margAdcSt);
    desabilitar(redBcSt);
    desabilitar(aliqIcmsSt);
    desabilitar(aliqFcpSt);
    desabilitar(aliqFcpRetSt);
    desabilitar(aliqBcOP);
    desabilitar(ufSt);

    if (typeof codigo !== 'undefined') {
      if (!nfce) {
        habilitar(cst);
      }
      switch (codigo.toString()) {
        case '101':
          /** Tributada pelo Simples Nacional com permissão de crédito */
          habilitar(aliqCalCred);
          break;
        case '102': /** Tributada pelo Simples Nacional sem permissão de crédito */
        case '103': /** Isenção do ICMS no Simples Nacional para faixa de receita bruta */
        case '300': /** Imune */
        case '400': /** Não tributada pelo Simples Nacional */
        case '500': /** ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação */
          break;
        case '201':
          /** Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária */
          habilitar(aliqCalCred);
          habilitar(aliqIcms);
          habilitar(modBcSt).trigger('change.select2');
          break;
        case '202': /** Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária */
        case '203':
          /** Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária */
          habilitar(aliqIcms);
          habilitar(modBcSt).trigger('change.select2');
          break;
        case '900':
          /** Outros */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqCalCred);
          habilitar(aliqIcms);
          //habilitar(modBcSt).trigger('change.select2');
          break;
        case '00':
          /** Tributada integralmente */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqIcms);
          habilitar(aliqFcp);
          break;
        case '10':
          /** Tributada e com cobrança do ICMS por substituição tributária */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqIcms);
          habilitar(aliqFcp);
          habilitar(modBcSt).trigger('change.select2');
          break;
        case '20':
          /** Com redução de base de cálculo */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqIcms);
          habilitar(aliqFcp);
          break;
        case '30':
          /** Isenta ou não tributada e com cobrança do ICMS por substituição tributária */
          habilitar(modBcSt).trigger('change.select2');
          break;
        case '40': /** Isenta */
        case '41': /** Não Tributada */
        case '50': /** Suspensão */
          break;
        case '51':
          /** Diferimento */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqIcms);
          habilitar(aliqFcp);
          break;
        case '60':
          /** ICMS cobrado anteriormente por substituição tributária */
          habilitar(aliqIcmsRet);
          habilitar(aliqFcpRet);
          break;
        case '70': /** Com redução de base de cálculo e cobrança do ICMS por substituição tributária */
        case '90':
          /** Outras */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqIcms);
          habilitar(aliqFcp);
          habilitar(modBcSt).trigger('change.select2');
          break;
        case 'Partilha 10': /** Entre UF origem e destino ou definida na legislação com Subst Trib */
        case 'Partilha 90':
          /** Entre UF origem e destino ou definida na legislação - outros */
          habilitar(modBc);
          habilitar(redBc);
          habilitar(aliqIcms);
          habilitar(modBcSt).trigger('change.select2');
          break;
        case 'Repasse 41': /** ICMS ST retido em operações interestaduais com repasses do ST - Não Trib. */
        case 'Repasse 60': /** ICMS ST retido em operações interestaduais com repasses do ST - Cobrado ant. */
          break;
        default:
          break;
      }
    }
    init = false;
  });

  modBcSt.on('change.select2', () => {
    st.show();

    desabilitar(precUniSt);
    desabilitar(margAdcSt);
    desabilitar(redBcSt);
    desabilitar(aliqIcmsSt);
    desabilitar(aliqFcpSt);
    desabilitar(aliqFcpRetSt);
    desabilitar(aliqBcOP);
    desabilitar(ufSt);

    if (partilhaRepasse.indexOf(csosn.find('option:checked').data('codigo').toString()) !== -1) {
      habilitar(aliqBcOP);
      habilitar(ufSt);
    }

    if (regimeNormal.concat(simplesNacional).indexOf(csosn.find('option:checked').data('codigo').toString()) !== -1) {
      habilitar(aliqFcpRetSt);
    }

    if (regimeNormal.indexOf(csosn.find('option:checked').data('codigo').toString()) !== -1) {
      habilitar(aliqFcpSt);
    }

    switch (modBcSt.val()) {
      case '0': /** Preço tabelado ou máximo sugerido */
      case '1': /** Lista Negativa (valor) */
      case '2': /** Lista Positiva (valor) */
      case '3': /** Lista Neutra (valor) */
      case '6':
        /** Valor da operação */
        habilitar(redBcSt);
        habilitar(aliqIcmsSt);
        break;
      case '4':
        /** Margem Valor Agregado (%) */
        habilitar(margAdcSt);
        habilitar(redBcSt);
        habilitar(aliqIcmsSt);
        break;
      case '5':
        /** Pauta (valor) */
        habilitar(precUniSt);
        habilitar(redBcSt);
        habilitar(aliqIcmsSt);
        break;
      default:
        break;
    }
  });

  csosn.trigger('change.select2');
};

window.initIcms = icms;
