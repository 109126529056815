import axios from 'axios';
import Inputmask from 'inputmask';

(function ($) {
  $.fn.buscaCep = function () {
    const mascaraCep = new Inputmask({
      mask: '99999-999',
      clearIncomplete: true
    });
    this.each(() => {
      const cep = $(this);

      mascaraCep.mask(cep);

      if (!cep.hasClass('hasCep')) {
        cep.addClass('hasCep');

        const settings = {
          campoEndereco: cep.data('endereco'),
          campoCidade: cep.data('cidade'),
          campoBairro: cep.data('bairro')
        };

        cep.blur(() => {
          if (/^([0-9]){5}([-])([0-9]){3}$/.test(cep.val())) {
            axios
              .get('/enderecos/get-endereco', {
                params: {
                  cep: cep.val()
                }
              })
              .then((data) => {
                const {endereco} = data;

                if (endereco.logradouro) $(settings.campoEndereco).val(endereco.logradouro);
                if (endereco.bairro) $(settings.campoBairro).val(endereco.bairro);

                $(settings.campoCidade)
                  .empty()
                  .append(
                    $('<option>')
                      .attr('value', endereco.cidade_id)
                      .text(endereco.localidade + '/' + endereco.uf)
                  )
                  .trigger('change');
                cep.parent().removeClass('has-error');
              })
              .catch((error) => {
                cep.parent().addClass('has-error');
              });
          } else {
            cep.parent().addClass('has-error');
          }
        });
      }
    });
    return this;
  };
}(jQuery));
